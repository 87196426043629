import { HTMLRichTextMapSerializer, RTLinkNode } from '@prismicio/client';

export const headingSerializer: HTMLRichTextMapSerializer = {
    heading1: ({ children }) => `<h1 class="heading">${children}</h1>`,
    heading2: ({ children }) => `<h2 class="heading">${children}</h2>`,
    heading3: ({ children }) => `<h3 class="heading">${children}</h3>`,
    paragraph: ({ children }) => `<p class="heading">${children}</p>`
};

export const titleMSerializer: HTMLRichTextMapSerializer = {
    heading1: ({ children }) => `<h1 class="text-size-m">${children}</h1>`,
    heading2: ({ children }) => `<h2 class="text-size-m">${children}</h2>`,
    heading3: ({ children }) => `<h3 class="text-size-m">${children}</h3>`,
    paragraph: ({ children }) => `<p class="text-size-m">${children}</p>`
};

export const titleLSerializer: HTMLRichTextMapSerializer = {
    heading1: ({ children }) => `<h1 class="text-size-l">${children}</h1>`,
    heading2: ({ children }) => `<h2 class="text-size-l">${children}</h2>`,
    heading3: ({ children }) => `<h3 class="text-size-l">${children}</h3>`,
    paragraph: ({ children }) => `<p class="text-size-l">${children}</p>`
};

export const titleXlSerializer: HTMLRichTextMapSerializer = {
    heading1: ({ children }) => `<h1 class="text-size-xl color-white">${children}</h1>`,
    heading2: ({ children }) => `<h2 class="text-size-xl color-white">${children}</h2>`,
    heading3: ({ children }) => `<h3 class="text-size-xl color-white">${children}</h3>`,
    paragraph: ({ children }) => `<p class="text-size-xl color-white">${children}</p>`
};

export const descriptionNoMarginSerializer: HTMLRichTextMapSerializer = {
    paragraph: ({ children }) => `<p class="mb-0">${children}</p>`
};

export function serializeHyperlink(children: string, node: RTLinkNode, cssClass?: string, defaultColor: boolean = false) {
    let link;
    const { link_type, url } = node.data;
    switch (link_type) {
        case 'Document': {
            const { type, uid } = node.data;
            switch (type) {
                case 'standard-page':
                    link = `/${uid!.replace('startseite', '')}`;
                    break;
            }
            break;
        }
        default:
            link = url;
    }

    if (defaultColor) {
        return `<a href="${link}">${children}</a>`;
    }

    return `<a href="${link}" class="text-decoration-underline color-red ${cssClass}">${children}</a>`;
}
